import { GetStaticProps } from 'next';
import { BlockRenderer, getStartpage } from 'helpers/umbracoApi';
import { HeroProps, StartPage } from 'types/umbraco';
import {
  getSharedPageProps as getSharedLayoutProps,
  SharedPageProps,
} from 'components/Layout';
import React from 'react';
import HeroBlock from 'components/umbraco/HeroBlock';
import Booking from 'components/booking/Booking';
import { GetAvailableProductsByFacility } from 'helpers/coreApi';
import { ProductDto } from 'types/generic';
import { HeadMeta } from 'components/HeadMeta';
import CampaignBanner from 'components/campaigns/CampaignBanner';

interface Props extends StartPage {
  productsByFacility: Record<string, ProductDto[]>;
  sharedPageProps: SharedPageProps;
}

export default function Home({
  sharedPageProps,
  productsByFacility,
  blocks,
  meta,
}: Props): JSX.Element {
  const firstBlockIsHeroBlock = blocks?.[0]?.contentTypeAlias === 'HeroBlock';
  console.log('from index.ts',sharedPageProps)

  return (
    <>
      <HeadMeta {...meta} />
      {firstBlockIsHeroBlock && (
        <HeroBlock {...(blocks[0] as HeroProps)}>
          <Booking
            facilities={sharedPageProps.facilities}
            productsByFacility={productsByFacility}
          />
        </HeroBlock>
      )}
      <CampaignBanner campaigns={sharedPageProps.campaigns} campaignLevel="Top" />
      <BlockRenderer blocks={blocks?.slice(firstBlockIsHeroBlock ? 1 : 0)} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {

  const startpage = await getStartpage();
  const sharedPageProps = await getSharedLayoutProps();
  const productsByFacility = await GetAvailableProductsByFacility();

  return {
    revalidate: 20,
    props: { ...startpage, productsByFacility, ...sharedPageProps },
  };
};
