import { CampaignLevelTypes, CampaignsDto, Discount } from "types/generic"

export const checkAvailbleDiscounts = (campaigns: CampaignsDto[], campaignLevel: CampaignLevelTypes, facilityId?: number, unitId?: number): Discount[] => {
    let activeCampaigns: CampaignsDto[] = [];
    let activeDiscounts: Discount[] = []
    if (campaignLevel === 'Top') {
        activeCampaigns = campaigns.filter((campaign) => campaign.appliesToAll);
    } else if (campaignLevel === 'Facility') {
        activeCampaigns = campaigns.filter((campaign) => campaign.appliesToFacilityId.includes(facilityId))
    } else if (campaignLevel === 'Unit') {
        activeCampaigns = campaigns.filter(campaign => campaign.appliesToAll || campaign.appliesToFacilityId.includes(facilityId))
    }

    if (campaignLevel === 'Unit') {
        activeCampaigns.forEach((campaign) => activeDiscounts = ([...activeDiscounts, ...campaign.discounts.filter(discount => discount.appliesToAll || discount.appliesToProductId.includes(unitId))]))
    } else {
        activeCampaigns.forEach((campaign) =>
            activeDiscounts = ([...activeDiscounts, ...campaign.discounts])
        );
    }
    return activeDiscounts
}

const calcuteDiscountedPrice = (price: number, discount: Discount): number => {
    return discount.discountType === 'fixed' ? price - discount.amountOff : price * (1 - (discount.percentOff / 100))
}

export const getDiscountPrice = (price: number, discount: Discount): number => {
    let returnPrice = price

    const discountPrice = calcuteDiscountedPrice(price, discount)
    if (discountPrice < returnPrice) {
        returnPrice = discountPrice > 0 ? discountPrice : 0
    }
    return Math.round(returnPrice * 100) / 100
}

export const getBestDiscount = (price: number, discounts: Discount[]): Discount => {
    let returnDiscount
    discounts.forEach(discount => {
        if (returnDiscount === undefined) {
            returnDiscount = discount
        } else {
            const currentDiscountPrice = calcuteDiscountedPrice(price, returnDiscount)
            if (currentDiscountPrice > calcuteDiscountedPrice(price, discount)) {
                returnDiscount = discount
            }
        }
    })
    return returnDiscount
}


export const discountText = (discount: Discount): string => {
    if (discount !== undefined) {
        let val = 'kr';
        if (discount.discountType === 'percent') {
            val = '%';
        }
        let length = 'under din första månad';
        if (!discount.includeFirstMonth) {
            length = 'under din första hela månad';
        }
        if (discount.discountedMonths > 0) {
            length = `under ${discount.discountedMonths} månader`;
        }
        let condition = '';

        if (discount.requireFirstOrder) {
            condition = ', gäller endast nya kunder';
        }
        return `${discount.discountType === 'percent' ? discount.discountValue : discount.amountOff}${val} rabatt ${length}${condition}.`;
    }
};

export default checkAvailbleDiscounts