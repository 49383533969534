import Head from 'next/head';
import React from 'react';
import { PageMetaData } from 'types/umbraco';

export function HeadMeta(meta: PageMetaData): JSX.Element {
  return (
    <Head>
      <title key="title">{meta.title || `${meta.nodeName} | Mitt Lager`}</title>
      {meta.description && (
        <meta key="description" name="description" content={meta.description} />
      )}
      {meta.canonical && <link rel="canonical" href={meta.canonical}></link>}
    </Head>
  );
}
