/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SelectProps } from '@chakra-ui/react';
import { colors } from './constants';

export const colourStyles = {
  // outer container of the input area
  control: (styles, { isFocused }): SelectProps => ({
    ...styles,
    paddingLeft: 40,
    paddingRight: 85,
    borderRadius: 45,
    height: 90,
    cursor: 'pointer',
    border: isFocused ? 0 : 0,
    boxShadow: isFocused ? 0 : 0,
    '&:hover': {
      border: isFocused ? 0 : 0,
    },
  }),
  // an ugly line separating the icon area from the input area
  // we are not using this
  indicatorSeparator: () => ({
    display: 'none',
  }),
  // the standard chevron dropdown icon
  // we are not using this
  dropdownIndicator: () => ({
    display: 'none',
  }),
  // the X used to clear input
  // we are not using this!
  clearIndicator: () => ({
    display: 'none',
  }),
  // the outer container of all the options
  menu: (styles) => ({
    ...styles,
    padding: 20,
    borderRadius: '1em',
    zIndex: '10',
  }),
  // the inner container of all the options
  menuList: (): unknown => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '@media (min-width: 640px)': {
      flexDirection: 'row',
    },
  }),
  option: (
    _,
    { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
  ): unknown => {
    return {
      fontWeight: isSelected || isFocused ? 'bold' : 'normal',
      cursor: 'pointer',
      color: isSelected
        ? colors.secondary[900]
        : isFocused
        ? colors.primary[900]
        : colors.secondary[900],
      backgroundColor: isFocused ? 'transparent' : 'transparent',
      marginBottom: '.5em',
      '&:last-of-type': {
        border: 0,
        marginBottom: 0,
      },
      '@media (min-width: 640px)': {
        flex: '1 0 calc(50% - 1em)',
        borderBottom: '1px solid ' + colors.secondary[500],
        paddingBottom: '.5em',
        '&:nth-of-type(even)': {
          marginLeft: '1em',
        },
        '&:last-of-type': {
          border: 0,
        },
      },
    };
  },
  // the input / typing
  input: (): unknown => ({
    paddingTop: 28,
    fontSize: 20,
    maxWidth: '75%',
    overflow: 'hidden',
    '& input': {
      fontWeight: 'bold',
    },
  }),
  // the placeholder text
  placeholder: () => ({
    position: 'absolute',
    fontSize: 20,
    paddingTop: 28,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    fontWeight: 'bold',
    opacity: 0.5,
  }),
  // the value in the input field after selecting a value
  singleValue: () => ({
    position: 'absolute',
    fontSize: 20,
    paddingTop: 28,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  }),
};
