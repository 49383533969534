import React, { useEffect, useState } from 'react';
import { CampaignLevelTypes, CampaignsDto, Discount } from 'types/generic';
import { Box, } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import { discountText } from 'helpers/discounts';

interface CampaignBannerProps {
    campaigns: CampaignsDto[];
    campaignLevel: CampaignLevelTypes
    id?: number;
    title?: string;
}

const CampaignBanner = ({
    campaigns,
    campaignLevel,
    id,
    title
}: CampaignBannerProps): JSX.Element => {
    const filteredCampaign = campaigns?.filter(campaign => campaign.isActive)
    const [activeCampaigns, setActiveCampaigns] = useState<CampaignsDto[]>([]);
    const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([]);
    const [discountIndex, setDiscountIndex] = useState<number>(0);

    useEffect(() => {
        let sortedCampaigns = [];
        if (campaignLevel === 'Top') {
            sortedCampaigns = filteredCampaign?.filter((campaign) => campaign.appliesToAll);
        } else if (campaignLevel === 'Facility') {
            sortedCampaigns = filteredCampaign?.filter((campaign) => campaign.appliesToFacilityId.includes(id))
        }
        setActiveCampaigns(sortedCampaigns);
    }, [id]);

    useEffect(() => {
        activeCampaigns?.forEach((campaign) => {
            if (campaign.discounts.length > 0) {
                setActiveDiscounts([...activeDiscounts, ...campaign.discounts])
            }
        }
        );
    }, [activeCampaigns]);

    const titleText = (discount: Discount): string => {
        let titleText = 'JUST NU:'
        if (discount !== undefined) {
            if (campaignLevel === 'Facility' && title) {
                titleText = `JUST NU i ${title.toLocaleUpperCase()} på utvalda förråd:`
            }
        }
        return titleText
    };
    console.log({activeCampaigns})

    return (
        <>
            {
                activeCampaigns?.length > 0 && activeDiscounts.length && <Box py={3} backgroundColor={colors.primary[900]}>
                    <Box
                        color={colors.white}
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box fontWeight={600} mr={3}>
                            {titleText(activeDiscounts[discountIndex])}
                        </Box>{' '}
                        {discountText(activeDiscounts[discountIndex])}
                    </Box>
                </Box>
            }
        </>
    );
};

export default CampaignBanner;
