import { Box, Button, FormLabel, Tooltip } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { FacilityDto, ProductDto } from 'types/generic';
import Select from 'react-select';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useRouter } from 'next/router';
import { colourStyles } from 'constants/_reactSelect';

export interface Props {
  facilities: FacilityDto[];
  productsByFacility: Record<string, ProductDto[]>;
}

export interface ReactSelectProps {
  value: string;
  label: string;
}

function Booking({ facilities }: Props): JSX.Element {
  const [
    selectedFacility,
    setSelectedFacility,
  ] = useState<ReactSelectProps | null>();
  const [placeholder, setPlaceholder] = useState<string>(
    facilities[0] ? facilities[0].name : null
  );

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const router = useRouter();

  const handleFacilityClick = (e) => {
    router.push(`/anlaggning/${e.value}`);
  };

  useEffect(() => {
    setPlaceholder(
      facilities[Math.floor(Math.random() * facilities.length)]?.name
    );
  }, [facilities]);

  return (
    <Box
      w="100%"
      maxW="lg"
      mt="10"
      pos="relative"
      onClick={() => setMenuIsOpen(!menuIsOpen)}>
      <Select
        inputId="bookingSelect"
        id={'bookingSelect'}
        isClearable={true}
        value={selectedFacility}
        styles={colourStyles}
        placeholder={facilities ? placeholder : '...'}
        onChange={(e) => handleFacilityClick(e)}
        options={facilities.map((x) => {
          return { value: x.slug, label: x.name };
        })}
        menuIsOpen={menuIsOpen}
      />

      <FormLabel htmlFor="bookingSelect" variant="reactSelectLabel">
        Visa förråd i
      </FormLabel>

      <Tooltip
        hasArrow={true}
        label={
          menuIsOpen
            ? 'Klicka för att komma till en anläggning'
            : 'Se våra anläggningar'
        }
        variant="reactSelectTooltip">
        <Button
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          variant="bookingBtn"
          aria-label={`Visa förråd i ${selectedFacility?.label}`}
          _hover={{ bg: colors.system.success[300] }}>
          {menuIsOpen ? (
            <ChevronDownIcon w="3em" h="3em" />
          ) : (
            <ChevronRightIcon w="3em" h="3em" />
          )}
        </Button>
      </Tooltip>
    </Box>
  );
}

export default Booking;
